import { graphql } from 'gatsby'
import Collection from '../containers/Collection.Author'

export default Collection

export const pageQuery = graphql`
  query allArticleByAuthorQuery(
    $skip: Int!
    $limit: Int!
    $slug: String!
  ) {
    collectionInfo: strapiAuthor(slug: {eq: $slug}) {
      id
      name
      slug
      title
      authorDescription {
        data {
          authorDescription
        }
      }
      social {
        name
        url
      }
    }

    posts: allStrapiArticle(
      filter: {authors: {elemMatch: {slug: {eq: $slug}}}}
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        date
        createdAt
        slug
        title
        id
        tags {
          name
          slug
        }
        body {
          data {
            id
            body
          }
        }
      }
      pageInfo {
        perPage
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        totalCount
      }
      totalCount
    }
    }
 `
