import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { attachPostDetails, attachAuthorDetails } from '@utils-blog'
import { readingTime } from 'reading-time-estimator'

const PageCollectionAuthors = ({
  data: { posts, collectionInfo },
  ...props
}) => {
  attachPostDetails(posts) // Attach missing post details
  attachAuthorDetails(collectionInfo)
  posts.nodes.forEach(ele => {
    if (ele.body) {
      const text = ele.body.data.body.replace(/(<([^>]+)>)/gi, '')
      ele.readingTime = readingTime(text, 220)
    }
  })

  return (
    <Layout {...props}>
      <Seo
        title={collectionInfo.name}
        description={collectionInfo.authorDescription.data.authorDescription}
       
      />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header='Published by Author' totalCount={posts.totalCount} />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <AuthorExpanded author={collectionInfo} />
          <Divider />
          {posts.nodes && (
            <CardList
              nodes={posts.nodes}
              callFrom='Articles'
              variant={['horizontal-md', 'vertical']}
              columns={[1, 2, 3, 3]}
            />
          )}
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination
          {...posts.pageInfo}
          {...collectionInfo}
          {...{ slug: `/author/${collectionInfo.slug}/` }}
        />
      </PreFooter>
    </Layout>
  )
}

export default PageCollectionAuthors
